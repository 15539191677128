import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { noteStamp } from '../../../../utils/dateFunctions';
import { lookupUserById, lookupPermission } from '../../../../actions/lookups';
import { addVehicleNote } from '../../../../actions/apiVehicleNotes';
import DeleteVehicleNote from './DeleteVehicleNote';

const VehicleNoteContainer = ({ vehicle, current_user, dealer_settings, installation, addVehicleNote, lookupUserById, related_users }) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [userTags, setUserTags] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [content, setContent] = useState('');
    const contentRef = useRef(null);

    useEffect(() => {
        const activeSuggestion = document.querySelector('.autocomplete-suggestions .active');
        if (activeSuggestion) {
            activeSuggestion.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }       
    }, [activeIndex]);

    const handleInput = useCallback((e) => {
        setContent(e.currentTarget.innerText);
        const newTags = { ...userTags };
        const sel = window.getSelection();

        Object.keys(userTags).forEach(tag => {
            const userId = userTags[tag];
            const selector = `span[data-userid="${userId}"]`;
            const foundSpans = contentRef.current.querySelectorAll(selector);

            if (foundSpans.length > 0) {
                foundSpans.forEach(spanElement => {
                    if (!spanElement.innerText.includes(tag)) {
                        const range = sel.getRangeAt(0);
                        if (spanElement.previousSibling) {
                            range.setStartAfter(spanElement.previousSibling);
                            range.setEndAfter(spanElement.previousSibling);
                        }
                        sel.removeAllRanges();
                        sel.addRange(range);
                        contentRef.current.removeChild(spanElement);
                        delete newTags[tag];
                        setUserTags(newTags);
                        setContent(contentRef.current.innerText);
                    }
                });
            }
        });

        const text = e.currentTarget.innerText;
        const lastIndex = text.lastIndexOf('@');
        if (lastIndex !== -1 && (lastIndex === 0 || text[lastIndex - 1].trim() === '')) {
            const query = text.slice(lastIndex + 1).split(/\s/)[0];
            if (query.length > 0) {
                const filteredSuggestions = related_users.filter(user =>
                    user.email.toLowerCase().includes(query.toLowerCase()) ||
                    user.given_name.toLowerCase().includes(query.toLowerCase()) ||
                    user.family_name.toLowerCase().includes(query.toLowerCase())
                );
                setSuggestions(filteredSuggestions);
                setShowSuggestions(true);
            } else {
                setSuggestions(related_users);
                setShowSuggestions(true);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    
    }, [related_users, userTags]);
    
    const SuggestionListItem = ({
        className, onClick, onMouseEnter,displayName, position
    }) => (
            <li
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                className={className}
            >
            {displayName} - {position}
            </li>
    );

    const handleSuggestionClick = useCallback((suggestion) => {
        const displayName = suggestion.given_name && suggestion.family_name
            ? `${suggestion.given_name} ${suggestion.family_name}`
            : suggestion.email;
        const userId = suggestion.id;
        const tagHtml = `<span class="text-primary" data-userid="${userId}">${displayName}</span>&nbsp;`;

        const sel = window.getSelection();
        if (sel.rangeCount > 0) {
            const range = sel.getRangeAt(0);
            const htmlContent = contentRef.current.innerHTML;

            const atIndex = htmlContent.lastIndexOf('@');

            if (atIndex !== -1) {
                const endOfTagIndex = htmlContent.indexOf(' ', atIndex) !== -1 ? htmlContent.indexOf(' ', atIndex) : htmlContent.length;
                const beforeAt = htmlContent.substring(0, atIndex);
                const afterTag = htmlContent.substring(endOfTagIndex);
                contentRef.current.innerHTML = beforeAt + tagHtml + afterTag;
                const selector = `span[data-userid="${userId}"]`;
                const foundSpans = contentRef.current.querySelectorAll(selector);
                const lastSpanNode = foundSpans[foundSpans.length - 1];

                if (lastSpanNode) {
                    const newRange = document.createRange();
                    const newSel = window.getSelection();
                    newRange.setStartAfter(lastSpanNode.nextSibling);
                    newRange.setEndAfter(lastSpanNode.nextSibling);
                    newSel.removeAllRanges();
                    newSel.addRange(newRange);
                }

                setUserTags(prevTags => ({
                    ...prevTags,
                    [displayName]: userId
                }));

                setContent(contentRef.current.innerText);
            }
            setShowSuggestions(false);
        }
    }, [setContent, setUserTags, setShowSuggestions]);

    
    const handleKeyDown = useCallback((e) => {
        if (showSuggestions) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setActiveIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1));
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setActiveIndex(prevIndex => Math.max(prevIndex - 1, 0));
            } else if (e.key === 'click') {
                e.preventDefault();
                if (activeIndex >= 0 && activeIndex < suggestions.length) {
                    handleSuggestionClick(suggestions[activeIndex]);
                }
            } else if (e.key === 'Enter') {
                e.preventDefault();
                if (activeIndex >= 0 && activeIndex < suggestions.length) {
                    handleSuggestionClick(suggestions[activeIndex]);
                }
            }
        } else if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default Enter behavior and send note
            setShowSuggestions(false);
            if (content) {
                const userIds = Object.values(userTags).join(',');

                let noteValue = content;

                Object.keys(userTags).forEach(displayName => {
                    const wrappedName = `-{${displayName}}-`;
                    const regex = new RegExp(displayName, 'g');
                    noteValue = noteValue.replace(regex, wrappedName);
                });

                const data = {
                    vehicle_id: vehicle.id,
                    value: userIds ? `${noteValue}{{${userIds}}}` : noteValue,
                    uid: current_user.user,
                    department_id: vehicle.department_id,
                };

                addVehicleNote(data, 'addVehicleNoteError');
                setContent('');
                if (contentRef.current) {
                    contentRef.current.innerText = '';
                }
                setUserTags({});
            }
        }
    }, [suggestions, activeIndex, showSuggestions, content, userTags, addVehicleNote, vehicle]);
    
    const addNote = useCallback(() => {
        setShowSuggestions(false);
        if (content) {
            const userIds = Object.values(userTags).join(',');

            let noteValue = content;

            Object.keys(userTags).forEach(displayName => {
                const wrappedName = `-{${displayName}}-`;
                const regex = new RegExp(displayName, 'g');
                noteValue = noteValue.replace(regex, wrappedName);
            });

            const data = {
                vehicle_id: vehicle.id,
                value: userIds ? `${noteValue}{{${userIds}}}` : noteValue,
                uid: current_user.user,
                department_id: vehicle.department_id,
            };

            addVehicleNote(data, 'addVehicleNoteError');
            setContent('');
            if (contentRef.current) {
                contentRef.current.innerText = '';
            }
            setUserTags({});
        }
    }, [content, userTags, addVehicleNote, vehicle.id, current_user.user, vehicle.department_id]);

    const dealerSettingValue = useCallback((name) => {
        let value = _.sortBy(_.filter(dealer_settings, o => o.key === name && o.dealership === installation.subdomain), 'id');
        if (value.length === 0) {
            value = _.sortBy(_.filter(dealer_settings, o => o.key === name && (!o.dealership)), 'id');
        }
        return value.length > 0 && value[0].value ? value[0].value : '';
    }, [dealer_settings, installation.subdomain]);

    const delete_notes = useMemo(() => current_user.position === 'admin' || current_user.position === 'super_admin', [current_user.position]);
    const delete_notes_dealership = useMemo(() => dealerSettingValue('delete_notes'), [dealerSettingValue]);

    function findChangeIndex(prev, current) {
        const length = Math.min(prev.length, current.length);
        for (let i = 0; i < length; i++) {
            if (prev[i] !== current[i]) return i;
        }
        return length;
    }

    const formatNoteValue = (noteValue) => {
        const regex = /-\{(.*?)\}-/g;
        const parts = [];
        let match;
        let lastIndex = 0;
        while ((match = regex.exec(noteValue)) !== null) {
            if (match.index > lastIndex) {
                parts.push(noteValue.substring(lastIndex, match.index));
            }
            parts.push(
                <span className="text-primary" key={match.index}>
                    {match[1]}
                </span>
            );
            lastIndex = match.index + match[0].length;
        }
        if (lastIndex < noteValue.length) {
            parts.push(noteValue.substring(lastIndex));
        }
        return parts;
    };

    return (
        <div className="container vehicle-display-notes">
            <div className="input-group">
                <div
                    ref={contentRef}
                    contentEditable
                    className="form-control"
                    onInput={handleInput}
                    onKeyDown={handleKeyDown}
                    tabIndex="0"  // Make div focusable
                    aria-label="Note editor"
                />

                <button className="btn btn-primary" onClick={addNote}>Add Note</button>
                <ul className="autocomplete-suggestions list-group">
                    {showSuggestions && suggestions.map((suggestion, index) => {
                        const isActive = index === activeIndex;
                        const className = `list-group-item list-group-item-action ${isActive ? 'active' : ''}`;
                        const displayName = suggestion.given_name && suggestion.family_name 
                            ? `${suggestion.given_name} ${suggestion.family_name}` 
                            : suggestion.email;
                        return (
                            <SuggestionListItem 
                                key={suggestion.id}
                                className={className}
                                onClick={() => handleSuggestionClick(suggestion)}
                                onMouseEnter={() => setActiveIndex(index)}
                                displayName = {displayName}
                                position={suggestion.position}
                            />
                        );
                    })}
                </ul>
            </div>
            <div className="vehicle-display-notes-items">
                {useMemo(() => _.sortBy(vehicle.vehicle_notes, note => new Date(note.created_at)).reverse(), [vehicle.vehicle_notes]).map((note) => {
                    const username = lookupUserById(note.uid);
                    const user = username.user_name ? username.user_name.split('@')[0] : '';

                    let delete_button = (
                        <div className="row space-left-0 space-right-0">
                            <div className="col-3 ReactNoteTime pad-right-0 pad-left-0">
                                {user}<br/>
                                {noteStamp(note.created_at)}
                            </div>
                            <div className="col-9">
                                <small>{formatNoteValue(note.value)}</small>
                            </div>
                        </div>
                    );

                    if (delete_notes_dealership && delete_notes && (note.uid === current_user.user)) {
                        delete_button = (
                            <div className="row space-left-0 space-right-0">
                                <div className="col-3 ReactNoteTime pad-right-0 pad-left-0">
                                    {user}<br/>
                                    {noteStamp(note.created_at)}
                                </div>
                                <div className="col-7">
                                <small>{formatNoteValue(note.value)}</small>
                                </div>
                                <div className="col-2 float-right">
                                    <DeleteVehicleNote note_id={note.id} />
                                </div>
                            </div>
                        );
                    }

                    return <div className="card" key={`note_${note.id}`}>{delete_button}</div>;
                })}
            </div>
            
        </div>
    );
};

const mapStateToProps = (state) => ({
    current_user: state.current_user,
    dealer_settings: state.settings_site.dealer_settings,
    installation: state.settings_dealerships.installation,
    related_users: state.settings_users.related_users,
});

const mapDispatchToProps = {
    lookupUserById,
    addVehicleNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleNoteContainer);