import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';

class AddOns extends Component {
	constructor(props) {
		super(props);

		this.state = { current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '' };
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card space-top-0">

					<h3 className="text-center pad-top-1">Plugins and Add-Ons</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text">
							<EditDealershipSettings {...this.props} type={'switch'} name="text_messaging" label="Text Messaging" value={this.dealerSettingValue('text_messaging')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="recall_api" label="Recall API" value={this.dealerSettingValue('recall_api')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="live_chat" label="Live Chat" value={this.dealerSettingValue('live_chat')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="support-chat" label="Support Chat" value={this.dealerSettingValue('support-chat')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="support-center" label="Support Center" value={this.dealerSettingValue('support-center')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="support-videos" label="Support Videos" value={this.dealerSettingValue('support-videos')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="support-tickets" label="Support Tickets" value={this.dealerSettingValue('support-tickets')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="support-admin" label="Support Admin" value={this.dealerSettingValue('support-admin')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="support-dealer-admin" label="Support Dealer Admin" value={this.dealerSettingValue('support-dealer-admin')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="reports" label="Reports" value={this.dealerSettingValue('reports')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="email-reports" label="Email Reports" value={this.dealerSettingValue('email-reports')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="report-defaults" label="Report Defaults" value={this.dealerSettingValue('report-defaults')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="timedashboard" label="Time Dashboard" value={this.dealerSettingValue('timedashboard')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="users" label="Users" value={this.dealerSettingValue('users')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="dealer-forms" label="Dealer Forms" value={this.dealerSettingValue('dealer-forms')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="calendar" label="Calendar" value={this.dealerSettingValue('calendar')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="messages" label="Messages" value={this.dealerSettingValue('messages')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="estimates" label="Estimates" value={this.dealerSettingValue('estimates')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="notifications" label="Notifications" value={this.dealerSettingValue('notifications')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="audits" label="Audits" value={this.dealerSettingValue('audits')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="feed-center" label="Feed Center" value={this.dealerSettingValue('feed-center')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="tab-settings" label="Tab Settings" value={this.dealerSettingValue('tab-settings')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="site-settings" label="Site Settings" value={this.dealerSettingValue('site-settings')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="dealer-settings" label="Dealer Settings" value={this.dealerSettingValue('dealer-settings')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="incoming-step-defaults" label="Incoming Step Defaults" value={this.dealerSettingValue('incoming-step-defaults')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="color-settings" label="Color Settings" value={this.dealerSettingValue('color-settings')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="department-setup" label="Department Setup" value={this.dealerSettingValue('department-setup')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="edit-columns" label="Edit Columns" value={this.dealerSettingValue('edit-columns')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="working-hours" label="Working Hours" value={this.dealerSettingValue('working-hours')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="goals" label="Goals" value={this.dealerSettingValue('goals')} />
							<EditDealershipSettings {...this.props} type={'switch'} name="delete_notes" label="Delete Notes" value={this.dealerSettingValue('delete_notes')} />
						</div>
					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return { 
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	 };
}
export default connect(mapStateToProps)(AddOns);
