import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AllInputs from '../../../../inputs/AllInputs';
import './VehicleDisplayTabView.scss';
import VehicleTab from '../VehicleTab';
import {
	lookupPermission,
	lookupIsAdmin,
	lookupAllowedSubdomainByName,
	vsearch,
} from '../../../../actions/lookups';
import {
	setCurrentDealership,
	resetRefresh,
	resetNav,
	resetAdmin,
	resetSite,
	setCurrentReportTab,
	resetDepartments,
	syncInitialData,
	resetDealerships,
} from '../../../../actions/apiSetInfo';
import { change_page } from '../../../../actions/toggleMenu';


class VehicleDisplayTabView extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { value: this.props.value || '', newurl: '' };
		this.department = this.department.bind(this);
		this.onChange = this.onChange.bind(this);
		this.gotoSubdomain = this.gotoSubdomain.bind(this);
		this.lookupProfilePermission = this.lookupProfilePermission.bind(this);
	}

	department(id) {
		const self = this;
		const department = _.sortBy(_.filter(self.props.all_departments, o => o.id == id), 'id');
		return department[0] || [];
	}

	gotoSubdomain() {
		const self = this;
		const dealership = self.props.lookupAllowedSubdomainByName(this.props.vehicle.subdomain);
		clearInterval(self.props.interval_number);

		setTimeout(() => {
			self.props.resetRefresh();
			self.props.setCurrentDealership(dealership.database, dealership.subdomain, dealership.id);
		}, 2000);
		setTimeout(() => {
			self.props.syncInitialData();
		}, 6000);
		setTimeout(() => {
			self.props.change_page('content');
			self.props.vsearch(this.props.vehicle.stock);
		}, 10000);
	}

	vehicleColumns() {
		if (this.props.vehicle.department_id) {
			const department = this.department(this.props.vehicle.department_id);

			if (department.department_properties) {
				var newObj = Object.keys(department.department_properties).map(key => department.department_properties[key]);
				return _.sortBy(newObj, 'order');
			}
			return [];
		}
		return [];
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	lookupProfilePermission(permission) {
		let permissionValue = '';
		const profile_permissions = this.props.current_user_profile.profile_permissions || [];

		if (profile_permissions.length > 0) {
			profile_permissions.map((obj) => {
				if (obj.key == permission) {
					permissionValue = obj.value;
				}
			});
		}
		return permissionValue;
	}



	render() {
		let body = '';
		const self = this;
		var setdisabled = true;
		const { vehicle } = this.props;
		if (this.props.lookupPermission('vehicle_edit') || this.props.lookupPermission('vehicle_edit_properties') || this.props.lookupIsAdmin() || this.lookupProfilePermission('allowed_move_pdi')) {
			setdisabled = false;

			if (this.lookupProfilePermission('pdi_complete') && this.props.vehicle.department_id !== 41) {
				setdisabled = true;
			}
		}
		var i = 0;
		if ((this.props.vehicle_tab === 'vehicle-body' || this.props.edit_vehicle !== vehicle.id) && ((this.props.expand_all === true) || (this.props.vehicle_tab === 'vehicle-body' && this.props.edit_vehicle === vehicle.id))) {
			body = <div className={(this.props.vehicle_tab === 'vehicle-body' || this.props.edit_vehicle !== vehicle.id ? 'pad-left-1 pad-right-0 ReactVehicleBody vehicle-tab-box active' : 'pad-left-1 pad-right-0 ReactVehicleBody vehicle-tab-box')}>
				<div className="col-md-12">
					<div className="tab-view-grid">
						{this.vehicleColumns().map((vehCol) => {
							const depCol = vehCol.vehicle_column;

							if ((vehCol.vehicle_column.location_only === true) && (!vehCol.vehicle_column.location.includes(self.props.installation.id))) {
								return;
							}

							const colObject = _.find(vehicle.vehicle_properties, { key: vehCol.vehicle_column.name }) || '';
							let colWidth = '3';
							if ((vehCol.vehicle_column.input_name === 'switch-needed-nolabel') || (vehCol.vehicle_column.name === 'detail vendor')) {
								colWidth = '3';
							} else if ((vehCol.vehicle_column.input_name === 'switch-with-description-invoice')) {
								colWidth = '12';
							} else if (vehCol.vehicle_column.name === 'thumbnail') {
								if (!colObject.value) {
									return;
								}
							} else {
								colWidth = '3';
							}

							const display_name = vehCol.vehicle_column.display_name || vehCol.vehicle_column.name;
							var help_note = '';
							if (vehCol.vehicle_column.helpnote === true) {
								help_note = <i className={'fa fa-info-circle text-info'} title={vehCol.vehicle_column.helpnote_text} />;
							}
							if(vehCol.vehicle_column.name !== 'thumbnail'){
								return <div className={`col-sm-${colWidth} tab-view-item`} key={`vc_${vehicle.id}_${vehCol.vehicle_column.name}`}>
									<label className="control-label clearfix">
										{vehCol.vehicle_column.name !== 'thumbnail' ? display_name : ''}
										{' '}
										{help_note}
									</label>

									<AllInputs
										value={colObject.value || ''}
										type={vehCol.vehicle_column.input_name}
										display_label_1={vehCol.vehicle_column.display_label_1}
										display_label_2={vehCol.vehicle_column.display_label_2}
										name={vehCol.vehicle_column.name}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={colObject.user_name}
										updated_at={colObject.updated_at}
										options={depCol.options || []}
										vehicle_column_id={vehCol.vehicle_column.id}
										dataurl={vehCol.vehicle_column.name == 'thumbnail' ? colObject.value : ''}
										vehicle={vehicle}
										tabindex={i}
									/>
								</div>;
							}
							else{
								return(<React.Fragment/>)
							}
						})}
					</div>
				</div>

			</div>;
		} else if (this.props.edit_vehicle === vehicle.id) {
			body = <VehicleTab tab={this.props.vehicle_tab} vehicle_id={vehicle.id} />;
		}

		if (vehicle.subdomain !== self.props.installation.subdomain && self.props.installation.subdomain !== vehicle.subdomain.substring(0, vehicle.subdomain.length - 1) && self.props.installation.show_all_subdomains !== true) {
			if (self.props.lookupAllowedSubdomainByName(vehicle.subdomain)) {
				body = <button className="btn btn-sm btn-success" onClick={this.gotoSubdomain}>
Go To Vehicle -> at
					{vehicle.subdomain}
					{' '}
					{self.department(vehicle.department_id).name}
           </button>;
			} else {
				body = <button className="btn btn-sm btn-warning">
Vehicle at
					{vehicle.subdomain}
					{' '}
					{self.department(vehicle.department_id).name}
           </button>;
			}
		}

		if (vehicle.sold === true || vehicle.deleted === true) {
			body = 'Please Reativate Vehicle to Edit';
		}

		return (
			<div className="pad-left-0 pad-right-0 vehicle-display-tab-view">
				{body}
			</div>
		);
	}
}

const mappedActions = {
	lookupPermission,
	lookupIsAdmin,
	lookupAllowedSubdomainByName,
	setCurrentDealership,
	resetRefresh,
	resetNav,
	resetAdmin,
	resetSite,
	setCurrentReportTab,
	vsearch,
	resetDepartments,
	change_page,
	syncInitialData,
};

function mapStateToProps(state) {
	return {
		current_department: state.settings_nav.department,
		vehicle_tab: state.settings_nav.menus.vehicle_tab,
		edit_vehicle: state.settings_nav.edit_vehicle,
		all_tabs: state.settings_site.all_tabs,
		expand_all: state.settings_nav.menus.expand_all,
		all_departments: state.settings_departments.original_departments,
		departments: state.settings_departments.departments,
		current_dealership: state.settings_dealerships.current_dealership,
		vehicle_notes: state.settings_refresh.vehicle_notes,
		current_user: state.current_user,
		mappedActions: state.settings_dealerships.installation,
		softname: state.settings_dealerships.installation.softname || '',
		installation: state.settings_dealerships.installation || '',
		vehicle_columns: state.settings_departments.vehicle_columns,
		dealerships: state.settings_dealerships.set_dealerships,
		interval_number: state.settings_nav.interval_number,
		default_profiles: state.settings_users.default_profiles,
		current_user_profile: state.settings_users.current_user_profile,
	};
}
export default connect(mapStateToProps, mappedActions)(VehicleDisplayTabView);
