import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	setVehiclePageNumber,
	setVehiclePageSize,
	setSortByData,
} from '../../actions/apiSetInfo';
import {
	getCurrentDepartment,
} from '../../actions/apiGetInfo';
import {
	lookupDepartmentById,
	lookupUserById,
} from '../../actions/lookups';
import {
	getDateTime,
	readableStamp,
	returnDaysFromTimestamp,
} from '../../utils/dateFunctions';

class SupportMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: '', errors: {}, isLoading: false, vehicles_count: 50, page_size: this.props.page_size, sort_by_data: this.props.sort_by_data,
		};
		this.onBlur = this.onBlur.bind(this);
	}

	onBlur(e) {
		this.setState({ [e.target.name]: '' });
	}

	selectOptions() {
		let step;
		const options = [];
		for (step = 5; step < 1000; step += 5) {
			const obj = { value: step, label: step };
			options.push(obj);
		}

		return options;
	}

	selectSortOptions() {
		let step;
		const optionsArray = [];
		const department = this.props.current_department || [];
		const department_properties = department.department_properties || [];

		const options = ['year', 'make', 'model', 'days', 'created_at', 'make', 'classification', 'stocktype'];

		const replacementStrings = {
			created_at: 'age',
			stocktype: 'stock type',
		  };

		options.map((sort_data) => {
			const obj = { value: sort_data, label: sort_data.replaceAll(/created_at|stocktype/g, match => replacementStrings[match]) };
			optionsArray.push(obj);
		});

		department_properties.map((dept_prop) => {
			const obj = { value: dept_prop.vehicle_column.name, label: dept_prop.vehicle_column.name.replaceAll(/created_at|stocktype/g, match => replacementStrings[match]) };
			optionsArray.push(obj);
		});

		return optionsArray;
	}

	render() {
		const self = this;
		const buttons = [];

		return (
			<div className="container-fluid searchBar bg-primary text-white">
				<div className="d-flex justify-content-around bg-dark">
					<div className="d-inline-flex p-2">
Current Site:
						{self.props.installation.subdomain}
						{' '}

					</div>
					<div className="d-inline-flex p-2">
						{' '}
Currently Logged in as:
						{self.props.current_user.user_name}
						{' '}

					</div>
					<div className="d-inline-flex p-2">
Profile:
						{self.props.current_user.position}
					</div>
				</div>

				<div className="d-flex flex-wrap bg-light">
					<div className="p2" />

					<div className="p2 pad-left-1">
						{buttons}
					</div>
					<div className="p2" />
					<div className="p2" />
				</div>

			</div>
		);
	}
}

const mappedActions = {
	lookupDepartmentById,
	lookupUserById,
	setVehiclePageNumber,
	setVehiclePageSize,
	setSortByData,
	returnDaysFromTimestamp,
	getCurrentDepartment,
};

function mapStateToProps(state) {
	return {
		active_vehicles: state.settings_refresh.active_vehicles,
		current_department: state.settings_departments.department,
		expand_all: state.settings_nav.menus.expand_all,
		sort_descending: state.settings_nav.menus.sort_descending,
		sort_by_data: state.settings_nav.menus.sort_by_data,
		page_number: state.settings_nav.vehicle_pagination.page_number,
		page_size: state.settings_nav.vehicle_pagination.page_size,
		current_user: state.current_user,
		installation: state.settings_dealerships.installation,
		current_user_profile: state.settings_users.current_user_profile,
		sidebar_status: state.settings_nav.menus.sidebar_status,
	};
}
export default connect(mapStateToProps, mappedActions)(SupportMenu);
